<template>
  <div id="app">
    <Nav class="mb-6" />
    <router-view class="px-4"/>
  </div>
</template>

<script>
import Nav from './components/Nav'
export default {
  name: 'App',
  components: {
    Nav
  },
  methods: {
    logout () {
      this.$store.dispatch('AUTH_LOGOUT')
        .then(() => this.$router.push('/login'))
    }
  }
}
</script>
