<template>
  <div class="container mx-auto">
    <div class="w-full max-w-xs mx-auto">
      <form @submit.prevent="login" class="px-8 pt-6 pb-8 mb-4 mt-24">
        <div class="mb-4">
          <label class="block text-gray-700 text-sm mb-2" for="username">
            Usuario
          </label>
          <input id="username" type="text"  v-model="usuario" required class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 text-sm leading-tight focus:outline-none focus:shadow-outline">
        </div>
        <div class="mb-6">
          <label class="block text-gray-700 text-sm mb-2" for="password">
            Contraseña
          </label>
          <input id="password" type="password" v-model="clave" required class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 text-sm mb-3 leading-tight focus:outline-none focus:shadow-outline">
        </div>
        <div class="flex items-center justify-end">
          <button type="submit" class="font-light bg-gray-700 hover:bg-gray-800 text-white text-sm py-1 px-4 rounded focus:outline-none focus:shadow-outline">
            Accesar
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data () {
    return {
      usuario: '',
      clave: ''
    }
  },
  methods: {
    login () {
      const { usuario, clave } = this
      this.$store.dispatch('AUTH_LOGIN', { usuario, clave })
        .then((response) => {
          if (!response) {
            this.usuario = ''
            this.clave = ''
            return
          }
          this.$router.push('/')
        })
    }
  }
}
</script>
