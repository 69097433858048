<template>
  <tr>
    <td v-if="venta.nombre !== ''" class="px-2 py-1 whitespace-no-wrap text-xs leading-4 font-normal text-gray-900" :class="(venta.nombre === 'TOTAL')?'font-semibold': 'font-normal'">
      <span v-if="venta.nombre === 'TOTAL'">{{ venta.nombre }}</span>
      <span v-if="venta.nombre !== '' && venta.nombre !== 'TOTAL'"><router-link :to="{ name: 'canal-sucursal', params: { id: venta.sucursal } }">{{ venta.nombre }}</router-link></span>
    </td>
    <td v-if="venta.nombre !== ''" class="px-2 py-1 whitespace-no-wrap text-xs leading-4 font-normal text-gray-900" :class="(venta.nombre === 'TOTAL')?'font-semibold': 'font-normal'">{{ venta.importe | currency }}</td>
    <td v-if="venta.nombre !== ''" class="px-2 py-1 whitespace-no-wrap text-xs leading-4 font-normal text-gray-900" :class="(venta.nombre === 'TOTAL')?'font-semibold': 'font-normal'">{{ venta.proyeccion | currency }}</td>
    <td v-if="venta.nombre !== ''" class="px-2 py-1 whitespace-no-wrap text-xs leading-4 font-normal text-gray-900" :class="(venta.nombre === 'TOTAL')?'font-semibold': 'font-normal'">{{ venta.importeb | currency }}</td>
    <td v-if="venta.nombre !== ''" class="px-2 py-1 whitespace-no-wrap text-xs leading-4 font-normal text-gray-900" :class="(venta.nombre === 'TOTAL')?'font-semibold': 'font-normal'">{{ venta.importec | currency }}</td>
    <td v-if="venta.nombre !== ''" class="px-2 py-1 whitespace-no-wrap text-xs leading-4 font-normal text-gray-900" :class="(venta.nombre === 'TOTAL')?'font-semibold': 'font-normal'">{{ venta.imported | currency }}</td>
    <td v-if="venta.nombre !== ''" class="px-2 py-1 whitespace-no-wrap text-xs leading-4 font-normal text-gray-900" :class="(venta.nombre === 'TOTAL')?'font-semibold': 'font-normal'">{{ venta.importee | currency }}</td>
    <td v-if="venta.nombre === ''" class="px-2 py-1 whitespace-no-wrap text-xs leading-4 font-normal text-gray-900"></td>
    <td v-if="venta.nombre === ''" class="px-2 py-1 whitespace-no-wrap text-xs leading-4 font-normal text-gray-900"></td>
    <td v-if="venta.nombre === ''" class="px-2 py-1 whitespace-no-wrap text-xs leading-4 font-normal text-gray-900"></td>
    <td v-if="venta.nombre === ''" class="px-2 py-1 whitespace-no-wrap text-xs leading-4 font-normal text-gray-900"></td>
    <td v-if="venta.nombre === ''" class="px-2 py-1 whitespace-no-wrap text-xs leading-4 font-normal text-gray-900"></td>
    <td v-if="venta.nombre === ''" class="px-2 py-1 whitespace-no-wrap text-xs leading-4 font-normal text-gray-900"></td>
    <td v-if="venta.nombre === ''" class="px-2 py-1 whitespace-no-wrap text-xs leading-4 font-normal text-gray-900"></td>
  </tr>
</template>

<script>
export default {
  name: 'ItemVentaProyeccion',
  props: {
    venta: Object
  }
}
</script>
