import axios from 'axios'
import jwt from 'jsonwebtoken'

const TOKEN_NAME = 'jwt_token'

class Auth {
  getAuthToken () {
    const token = localStorage.getItem(TOKEN_NAME)
    return 'Bearer '.concat(token)
  }

  getToken () {
    return localStorage.getItem(TOKEN_NAME)
  }

  setToken (token) {
    localStorage.setItem(TOKEN_NAME, token)
  }

  isAuthenticated (token) {
    if (!token) token = this.getToken()
    if (!token) return false
    if (token === 'null') return false
    const isExpired = jwt.decode(token).exp * 1000
    const date = new Date().getTime()
    return date < isExpired
  }

  ensureAuth () {
    const token = this.getToken()
    if (!token) return
    if (token === 'null') return
    const decodedToken = jwt.decode(token)
    return decodedToken.data
  }

  logout () {
    localStorage.removeItem(TOKEN_NAME)
  }

  login (user) {
    return axios.post('/api/usuario/login', user)
      .then(function (response) {
        return response.data
      })
  }
}

const authClient = new Auth()

export default authClient
