<template>
  <div class="container mx-auto">
    <div class="flex flex-row mb-6">
      <router-link to="/" class="inline-flex text-gray-700 hover:text-gray-900 border px-4 py-1 rounded text-xs border-gray-600 hover:bg-gray-300">Regresar a Acumulado</router-link>
    </div>
    <div class="flex flex-row mb-1">
      <h3 class="block text-lg uppercase leading-5 font-semibold text-blue-800">Proyeccion</h3>
    </div>
    <div class="flex items-center justify-center mb-6">
      <label for="fecha" class="block text-sm leading-5 font-medium text-gray-700 mr-4">{{ fechaDiaInicial | moment("DD MMMM YYYY") }}</label>
    </div>
    <ListVentasProyeccion :ventas="ventas" :periodos="periodos" class="mb-6"/>
  </div>
</template>

<script>
import authClient from '@/utils/auth'
import { periodos } from '@/utils/periodos'
import ListVentasProyeccion from '@/components/ListVentasProyeccion'
import moment from 'moment-timezone'
export default {
  name: 'Proyeccion',
  components: {
    ListVentasProyeccion
  },
  data () {
    return {
      fechaDiaInicial: moment().tz('America/Belize').format('YYYY-MM-DD'),
      ventas: [],
      periodos: periodos()
    }
  },
  methods: {
    obtenerVentas () {
      const fechaInicial = moment().tz('America/Belize').format('YYYY-MM-DD')
      const fechaFinal = moment().tz('America/Belize').format('YYYY-MM-DD')
      this.$http.post('/api/ventas/proyeccion', { fechaInicial: fechaInicial, fechaFinal: fechaFinal }, {
        headers: { Authorization: authClient.getAuthToken() }
      }).then(({ data }) => {
        this.ventas = data.result
      })
    }
  },
  mounted () {
    this.obtenerVentas()
  }
}
</script>
